@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

* {
  font-family: 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  font-size: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
}

.pull-right {
  float: right !important;
}

.mr-none {
  margin-right: 0 !important;
}

.MuiPaginationItem-textSecondary.Mui-selected {
  color: #fff;
  background-color: #00e676 !important; /* #00e676 */
}

.MuiBadge-colorError {
  margin-top: 8% !important;
  margin-right: 21% !important;
}

.rce-avatar-container.large {
  display: none;
}

.sujetColor {
  color: blue !important;
}

.MuiAutocomplete-popper {
  z-index: 2600 !important;
}

.MuiTab-labelIcon {
  min-height: 0 !important;
}

.ReactModal__Content {
  top: 56px !important;
}

.IconListItemActionDetails {
  width: 20px !important;
  height: 20px !important;
  margin-right: 9px;
  color: #373d57;
}

.gradient-border {
  background-color: rgb(248 248 248);
  width: 100%;
  top: 55px;
  z-index: 3;
  position: sticky;

  --borderWidth: 2px;

  /* background: #1D1F20; */
  border-radius: var(--borderWidth);
}

.gradient-border::after {
  content: '';
  position: absolute;
  top: calc(-2 * var(--borderWidth));
  right: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    to right,
    rgba(0, 212, 255, 1) 10%,
    rgba(25, 118, 210, 1) 80%,
    rgba(20, 3, 128, 1) 100%
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 800ms linear infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    right: 200%;
    background-position: 0% 0%;
  }

  50% {
    right: 100%;
    background-position: 50% 50%;
  }

  100% {
    right: 0%;
    background-position: 100% 100%;
  }
}
