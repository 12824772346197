@import '../../../assets/styles/sizes.scss';
@import '../../../assets/styles/form.scss';
@import '../../../assets/styles/colors.scss';

$step-height: 85px; ///110px;
$page-max-width: 1800px;
$recap-header-height: 25vh;
$recap-footer-height: 120px;

.column-container {
  margin-bottom: 100px;
}

.place-order-page {
  //margin-top: $step-height;
  min-height: calc(100vh - #{$header-height} - #{$step-height});
  position: relative;
  max-width: $page-max-width;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;

  &.has-loader {
    opacity: 0.3;
  }

  overflow-x: hidden;

  .column-container {
    margin-bottom: 100px;
  }
}

.appbarFooter {
  top: auto;
  height: 70px;
  bottom: 0;
  display: block;
  align-items: center;
  align-content: center;
}

.Loading {
  background: transparent !important;
}

.MuiStepper-alternativeLabel {
  padding: 5px !important;
  // margin-top: 19px;
}

.MuiStepLabel-label {
  &.MuiStepLabel-alternativeLabel {
    margin-top: 8px !important;
    font-size: 14px;
  }
}

.col-left-container {
  display: none;

  &.active {
    display: block;
  }
}

.order-item {
  position: relative;

  .item-nbr {
    display: flex;
    position: absolute;
    top: 30px;
    right: 50px;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }

  .order-sub-item {
    border-top: 3px solid #9e9e9e !important;
    border-top-style: dotted !important;
    margin-top: 30px;
    padding-top: 15px;

    &.not-active {
      opacity: 0;
      height: 0;
    }

    .remove-icon {
      position: absolute;
      cursor: pointer;
      right: 5px;
    }
  }

  .not-active {
    opacity: 0;
    height: 0;
    overflow: hidden !important;

    & ~ .order-sub-item {
      border-top: none !important;
    }
  }
}

.place-order-body {
  position: relative;
  width: 100%;
  padding: 15px 0 0 15px;

  .left-col {
    position: relative;
    float: left;
  }

  .right-col {
    .col-container {
      height: 100%;
      overflow: scroll;
      justify-content: center;

      .order-service {
        display: block;
        position: relative;
        height: 40px;
        width: 300px;

        button {
          width: 100%;
        }
      }

      .place-order-step-buttons {
        display: block;
        position: absolute;
        text-align: right;
        bottom: 0;
        right: 0;
        float: right;

        button {
          &:last-child {
            margin-bottom: 5px;
            margin-right: 0;
          }
        }
      }
    }
  }

  &.sm-matches {
    .left-col,
    .right-col {
      width: 50%;
    }

    .left-col {
      .col-container {
        padding-right: 15px;
      }
    }

    .right-col {
      width: 50%;
      top: calc(#{$header-height} + #{$step-height});
      position: fixed;
      left: calc(50vw - 15px);
      max-width: calc(#{$page-max-width} / 2);
      height: 100%;

      .recap-header {
        max-height: 145px;

        .items-list {
          max-height: 110px;
          min-height: 54px;
          overflow: auto;

          .MuiGrid-item {
            white-space: nowrap;
            vertical-align: middle;
            align-items: center;
            display: flex;
          }
        }
      }

      .col-container {
        padding-left: 15px;

        #map-container {
          height:
            calc(
              100vh - #{$header-height} - #{$step-height} - #{$recap-header-height} - #{$recap-footer-height} -
              15px
            );
        }
      }
    }
  }

  &:not(.sm-matches) {
    .left-col,
    .right-col {
      width: 100%;
    }
  }
}

.MuiFormControl-root {
  width: 100%;
  //background-color: white;
}

.form-text-error {
  position: absolute;
  top: 100%;
  left: 0;
}

.recap-map {
  margin-bottom: 2%;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #00e676 !important;
}

.right-section-container {
  width: calc(100% - 50px);
  margin: 10px 30px 10px 20px;
  position: sticky;
  top: 90px;
  z-index: 3;
}

@media (max-width: 960px) {
  .place-shipment {
    width: calc(100% - 20px);
    margin: 20px 10px;
  }

  .place-order-page .order-item .item-nbr {
    top: 38px;
    right: 30px;
  }

  .right-section-container {
    width: calc(100% - 20px);
    margin: 20px 10px;
  }
}
