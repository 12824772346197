.fab-button {
  width: 35px !important;
  height: 35px !important;
  margin: 0 2px !important;
  box-shadow: none !important;
}

.fab-extended-button {
  height: 30px !important;
  padding: 0 5px !important;
  margin: 0 2px !important;

  .MuiFab-label {
    text-transform: capitalize !important;
  }

  .MuiSvgIcon-root {
    width: 20px;
  }

  box-shadow: none !important;
}

.fab-transparent {
  background-color: transparent !important;
  color: transparent !important;
  box-shadow: none !important;
}
