form {
  &.form-validation {
    &:not(.was-submitted) {
      .MuiFormLabel-root {
        &.Mui-error {
          // color: rgba(0, 0, 0, 0.54) !important;
        }
      }

      .form-text-error {
        display: none;
      }
    }

    input[type='submit'] {
      &.hidden {
        opacity: 0;
        height: 0;
      }
    }
  }
}
