.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100vh;
  z-index: 1300;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  opacity: 0.5;
}
