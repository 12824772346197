.root {
  flex-grow: 1;
  max-width: 510px;
  max-height: 360px;
  text-align: center;
  padding: 16px;
  display: grid;
  font-size: 20px;
  place-items: center;
  margin: auto;
}

.button {
  width: fit-content;
  margin: 0 auto !important;
}
