.login-page {
  form {
    display: grid;

    a {
      float: right;
    }
  }

  &.form-container {
    display: block;
    max-width: 450px;
    padding: 30px;
    position: absolute;
    // top: 0;
    // bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: fit-content;
  }

  input {
    padding-right: 30px !important;
  }

  .error-text {
    float: left;
    margin: 10px 0;
    color: red !important;
    text-align: center;
    font-size: 15px;
  }

  span {
    text-align: center;
    width: 100%;
  }

  .forget-password {
    float: right;
  }

  .login-error-text {
    text-align: center;
    width: 100%;
    font-size: inherit;
  }

  .google-button {
    font-size: inherit !important;
    width: 100%;
  }

  .b2h-logo {
    width: 100%;
  }

  .MuiFormControl-root {
    width: 100%;
    margin: 10px 0;
  }
}

.login-page-warning {
  form {
    display: grid;

    a {
      float: right;
    }
  }

  &.form-container {
    display: block;
    max-width: 450px;
    padding: 0 20px 0;
    position: absolute;
    // top: 0;
    // bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: fit-content;
  }

  input {
    padding-right: 30px !important;
  }

  .error-text {
    float: left;
    margin: 10px 0;
    color: red !important;
    text-align: center;
    font-size: 15px;
  }

  span {
    text-align: center;
    width: 100%;
  }

  .forget-password {
    float: right;
  }

  .login-error-text {
    text-align: center;
    width: 100%;
    font-size: inherit;
  }

  .google-button {
    font-size: inherit !important;
    width: 100%;
  }

  button {
    background-color: #00bfdd;
    color: white;
  }

  .MuiButton-textPrimary {
    justify-content: flex-end !important;
    text-transform: none !important;
  }

  .b2h-logo {
    width: 100%;
  }

  .MuiButton-contained.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: grey !important;
  }

  .login-button {
    background-color: #1976d2 !important;
    color: white !important;
    width: 100%;
    height: 40px;
  }

  .MuiFormControl-root {
    width: 100%;
    margin: 10px 0;
  }
}
