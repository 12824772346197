.recap-section-container {
  position: relative;
  height: 47px;

  .first-cell {
    position: relative;

    .step-nbr {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .fields-list {
    margin-left: 50px;
  }

  .last-cell {
    position: relative;
    border-bottom: none;

    .remove-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .bold-style-text {
    font-weight: 700;
  }

  .recap-title {
    display: grid;
    font-size: 25px;
    color: #063c52;

    p {
      margin: 1px 0;
      font-size: 25px;
      font-weight: 400;
    }
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
    background-color: transparent;
  }

  .MuiTableCell-root {
    position: relative;
  }
}

.cell-item-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  z-index: 9;
  position: relative;
  background-color: #ffffffbd;
  margin-bottom: 5px;

  .cell-item {
    display: flex !important;
    white-space: nowrap;
    text-overflow: ellipsis;

    .MuiFormControlLabel-root {
      margin-right: 0;
    }

    .cell-text {
      font-size: 18px;
      color: #7f7f7f;
      margin-top: 2px;
    }

    .cell-icon {
      color: #7f7f7f;
      font-size: 2rem;
      margin-right: 5px;
    }
  }

  .checkbox-style {
    svg {
      width: 20px;
      height: 20px;
      color: #00e676;
    }
  }
}

.order-type {
  font-size: 14px;
  font-weight: 700;
}

::-webkit-scrollbar {
  height: 5px !important;
}
