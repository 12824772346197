// .MuiSwitch-root {
//   .MuiSwitch-colorPrimary.Mui-checked {
//     color: #196dd4 !important; //#5a54a1
//   }

//   .MuiSwitch-track {
//     background-color: #00e676 !important; // #00e676
//   }
// }

.navbar-container {
  overflow: hidden;
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 2500;
}

.Mui-checked {
  color: #00e676 !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}
