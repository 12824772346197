.empty-table-container {
  display: flex;
  margin: auto;
  width: fit-content;

  .MuiSvgIcon-root {
    font-size: 19px !important;
    margin: auto 5px !important;
  }

  .empty-table {
    font-weight: 500;
    font-size: 12px;
    color: dimgrey;
  }
}
